/*eslint-disable*/
import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
// react components for routing our app without refresh
import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import { Apps, CloudDownload, CropFree, ExitToApp, ListAlt } from "@material-ui/icons";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";
import { configureAnchors } from 'react-scrollable-anchor';
import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);

configureAnchors({offset: -250, scrollDuration: 300});

export default function HeaderLinks(props) {
  const classes = useStyles();
  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText="Curso"
          buttonProps={{
            className: classes.navLink,
            color: "transparent"
          }}
          buttonIcon={ListAlt}
          dropdownList={[
            <a href="#what" className={classes.dropdownLink}>
              Descripción
            </a>,
            <a href="#method" to="/" className={classes.dropdownLink}>
              Contenido
            </a>,
            <a href="#results" to="/" className={classes.dropdownLink}>
              Competencias a desarrollar
            </a>
          ]}
        />
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          href="https://www.fuam.es/curso-corto/management-de-entidades-deportivas-3a-edicion/"
          color="transparent"
          target="_blank"
          className={classes.navLink}
        >
          <ExitToApp className={classes.icons} /> Matriculas y más info
        </Button>
      </ListItem>
    </List>
  );
}
