/* eslint-disable no-unused-vars */
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import ScrollableAnchor from 'react-scrollable-anchor';
import styles from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.js";
import CardHeader from "components/Card/CardHeader.js";

import team1 from "assets/img/faces/avatar.jpg";
import team2 from "assets/img/faces/christian.jpg";
import team3 from "assets/img/faces/kendall.jpg";

const useStyles = makeStyles(styles);

export default function MethodSection() {
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  return (
    <div className={classes.section}>
      <h2 className={classes.title}>Contenido</h2>
      <ScrollableAnchor id={'method'}>
      <h5 className={classes.description}>
      Durante el curso, estudiaremos seis módulos formativos que recorren diferentes aspectos del Management Deportivo, a saber:
      </h5>
      </ScrollableAnchor>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
          <Card style={{height: "30rem"}}>
            <CardHeader color="info"><span style={{ fontSize: 19, fontWeight: 'bold' }}>Módulo 1: Presente y futuro de la Economía del Deporte</span></CardHeader>
              <CardBody>
                <p>
                  La economía del deporte establece un marco de actuación amplio en el que caben las políticas públicas, el análisis económico y el ecosistema de actores que interaccionan como las entidades deportivas (clubes, asociaciones, federaciones, ligas, comités, etc.), las empresas del sector deportivo (orientadas a los productos deportivos, gestores de instalaciones, equipamientos y eventos, así como el contexto de las apuestas deportivas y todo el movimiento emprendedor), lo que genera una base de conocimiento relevante para comenzar este itinerario formativo.
                </p>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
          <Card style={{height: "30rem"}}>
            <CardHeader color="info"><span style={{ fontSize: 19, fontWeight: 'bold' }}>Módulo 2: Estrategia y modelos de negocio en organizaciones deportivas</span></CardHeader>
              <CardBody>
                <p>
                  En la actualidad, la configuración estratégica y los modelos de negocio en organizaciones deportivas, conviven en un entorno dinámico, altamente competitivo y cada vez más digital. La creatividad, los datos y la inteligencia en los negocios, son uno de los aspectos fundamentales para la sostenibilidad de organizaciones deportivas.
                </p>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
          <Card style={{height: "30rem"}}>
          <CardHeader color="info"><span style={{ fontSize: 19, fontWeight: 'bold' }}>Módulo 3: Finanzas y Administración de organizaciones deportivas</span></CardHeader>
              <CardBody>
                <p>
                  En los últimos años se ha puesto de manifiesto la importancia de la función financiera en la gestión de las entidades deportivas. El correcto análisis de la situación financiera o el conocimiento de las opciones de obtención de fondos son aspectos cruciales en la gestión de una organización deportiva. Adicionalmente la planificación de los ingresos futuros y los gastos e inversiones a realizar  constituye un elemento básico de la administración de una institución deportiva con un objetivo de crecimiento.
                </p>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
          <Card style={{height: "30rem"}}>
          <CardHeader color="info"><span style={{ fontSize: 19, fontWeight: 'bold' }}>Módulo 4: Liderazgo, Innovación y Tecnología en organizaciones deportivas</span></CardHeader>
              <CardBody>
                <p>
                 La Economía del Conocimiento pone de manifiesto la necesidad de plantear estrategias y modelos donde la innovación sea protagonista de cara a cubrir la renovación de las propuestas de valor que cada vez cuenta con ciclos de vida más cortos. De esta forma, la consideración de los activos intangibles que componen las competencias de la organización junto con la agilidad asociada al aprovechamiento del conocimiento como base para los planteamiento de I+D+i son cuestiones que deben estar en la agenda de los directores y gestores de las organizaciones deportivas.
                </p>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
          <Card style={{height: "30rem"}}>
          <CardHeader color="info"><span style={{ fontSize: 19, fontWeight: 'bold' }}>Módulo 5: Marketing y Comunicación de organizaciones deportivas</span></CardHeader>
              <CardBody>
                <p>
                  Cuando hablamos de marketing deportivo, pensamos en los departamentos de marketing de grandes clubes de fútbol o la NBA. Pero el marketing deportivo abarca mucho más que eso. Federaciones, tiendas de material deportivo, gimnasios, piscinas, clubes deportivos de todo tipo,... Gracias a este módulo de marketing deportivo podrás tener una idea global de lo que supone gestionar un departamento de marketing dentro del sector deportivo más allá de lo mediático, dando gran importancia al uso y aplicación de las nuevas tecnologías.
                </p>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
          <Card style={{height: "30rem"}}>
          <CardHeader color="info"><span style={{ fontSize: 19, fontWeight: 'bold' }}>Módulo 6: Aspectos jurídicos en los negocios deportivos</span></CardHeader>
              <CardBody>
                <p>
                  El sector deportivo es especialmente relevante en España, tanto el deporte amateur como, especialmente, el deporte profesional. Los éxitos de nuestros deportistas y asociaciones y clubes deportivos así lo avalan. El deporte tiene varias dimensiones de enorme importancia, más allá de la práctica de una actividad física saludable, especialmente la dimensión económica del fenómeno y, ligado a ella, la dimensión jurídica. Esta última es de vital importancia para la regulación de las organizaciones encargadas de articular la práctica deportiva , las relaciones laborales , las relaciones de patrocinio y la fiscalidad.
                </p>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
/* eslint-disable no-unused-vars */