import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import { IntlProvider, addLocaleData } from 'react-intl';
import en from "react-intl/locale-data/en";
import es from "react-intl/locale-data/es";
import messages from './messages';
import SiteProvider from './SiteProvider'
import App from './App';
import * as serviceWorker from './serviceWorker';

addLocaleData([...en, ...es]);

const locale = 'es-ES' || (navigator.languages && navigator.languages[0])
               || navigator.language
               || navigator.userLanguage;

ReactDOM.render(
  <SiteProvider>
    <IntlProvider locale={locale} messages={messages[locale]}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </IntlProvider>
  </SiteProvider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();