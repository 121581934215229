/* eslint-disable no-unused-vars */
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Chat from "@material-ui/icons/Chat";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import Fingerprint from "@material-ui/icons/Fingerprint";
import MapIcon from '@material-ui/icons/Map';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import SpaRoundedIcon from '@material-ui/icons/SpaRounded';
import HomeWorkIcon from '@material-ui/icons/Business';
import SchoolIcon from '@material-ui/icons/School';
import PersonalVideoIcon from '@material-ui/icons/PersonalVideo';
import CastForEducationIcon from '@material-ui/icons/CastForEducation';
import EventIcon from '@material-ui/icons/Event';

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import ScrollableAnchor from 'react-scrollable-anchor';

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);

export default function ProductSection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>Management de Entidades Deportivas</h2>
          <ScrollableAnchor id={'what'}>
          <h5 className={classes.description}>
          El deporte va más allá de una práctica saludable y se incorpora a la realidad económica con sus particularidades de dirección y gestión que deben ser atendidas por profesionales que puedan comprender las necesidades y oportunidades que ofrece este sector de actividad. Este diploma se orienta a las dimensiones esenciales para desarrollar competencias clave en el management deportivo.
          </h5>
          </ScrollableAnchor>             
        </GridItem>
      </GridContainer>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={10}>
          <h5 className={classes.description}>
           Dirigido a: Responsables e interesados en dirigir organizaciones y proyectos empresariales deportivos que requieren un marco de estrategia y funcionamiento profesional para hacer sostenible y potenciar sus ofertas y propuestas de valor.
          </h5>
          <h5 className={classes.description}>
            Duración: 120 horas (48 horas síncronas online + 72 horas asíncronas online).
          </h5>
          <h5 className={classes.description}>
            Titulación: Diploma de la UAM. Este es un curso organizado por la Universidad Autónoma de Madrid.
          </h5>                    
        </GridItem>
      </GridContainer>
      <div style={{ marginBottom: "35px" }}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="¿Cuándo?"
              description="Del 15 de Septiembre al 1 de Noviembre del 2022."
              icon={EventIcon}
              iconColor="info"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Modalidad"
              description="La duración total del curso será de 150 horas : 54 horas síncronas online y 96 horas asíncronas de trabajo online."
              icon={CastForEducationIcon}
              iconColor="success"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Titulación"
              description="Diploma de la UAM. Curso organizado por la Universidad Autónoma de Madrid."
              icon={SchoolIcon}
              iconColor="danger"
              vertical
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
/* eslint-disable no-unused-vars */