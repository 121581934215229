/* eslint-disable no-unused-vars */
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import ScrollableAnchor from 'react-scrollable-anchor';
import styles from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.js";

import team1 from "assets/img/faces/avatar.jpg";
import team2 from "assets/img/faces/christian.jpg";
import team3 from "assets/img/faces/kendall.jpg";

const useStyles = makeStyles(styles);

export default function ResultsSection() {
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  return (
    <div className={classes.section}>
      <h2 className={classes.title}>Competencias a desarrollar</h2>
      <ScrollableAnchor id={'results'}>
      <h5 className={classes.description}>
        Con este curso se podrán desarrollar habilidades y competencias como:
      </h5>
      </ScrollableAnchor>
      <div>
        <h5 className={classes.description}>
        •	Comprensión de las bases de la nueva economía y su relación con las particularidades del sector de las organizaciones deportivas.<br />
        •	Manejo de las dimensiones clave para la tratar tanto la estrategia como la operativa de estas organizaciones.<br />
        •	Control de los asuntos administrativos, financieros, contables y de inversión considerando su relación con el marco de transparencia.<br />
        •	Desarrollo de nuevas propuestas de valor basadas en la innovación y tecnología como drivers de competitividad.<br />
        •	Manejo de las estrategias de marketing que faciliten el posicionamiento de las organizaciones y proyectos empresariales.<br />
        •	Comprensión del marco legal diverso que impacta sobre el management deportivo.
        </h5>
      </div>
    </div>
  );
}
/* eslint-disable no-unused-vars */